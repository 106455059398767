export const useTracking = () => {
  const { gtag } = useGtag();
  const config = useRuntimeConfig();
  const isProduction = config.public.env === "production";

  const trackingService = new TrackingService(gtag, isProduction);
  
  type TrackingEventType = typeof TrackingEvent[keyof typeof TrackingEvent];

  return {
    track: (event: TrackingEventType, data?: any) => trackingService.track(event, data),
  };
};
